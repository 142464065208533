<template>
  <div v-if="settings" class="login-container">
    <div class="main-wrapper">
      <div class="image-container">
        <img src="../assets/images/Login.png" alt="Login Image" class="login-image"/>
      </div>
 
      <div class="content-container">
        <p class="title">Login</p>
        <form>
          <div class="input-group">
            <label for="email" class="input-label">Email</label>
            <input type="email" name="email"
            placeholder="mail@example.com"
            v-model="details.email"
            id="email"
            class="input-field">
          </div>
          <div class="input-group">
            <label for="password" class="input-label">Password</label>
            <input type="password" placeholder="********************"
            name="password"
            v-model="details.password"
            id="password"
            class="input-field">
            <p v-if="showError" class="error-message">Email or Password is incorrect</p>
          </div>
          <div class="button-container">
            <button type="button" @click="login" class="login-button">
               Login
            </button>
          </div>
          <div class="links-container">
            <a href="/forgot-password" v-if="settings" class="forgot-password-link">Forgot Password?</a>
            <div class="signup-container">
              <p class="no-account-text">Don't have an account?</p>
              <router-link to="/register" class="signup-link">SignUp</router-link>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
 
 
 
 
 
 
 
 
<script>
import axios from 'axios'
import { mapGetters, mapActions } from "vuex"
import Download from '@/components/Downloads.vue'
import { notify } from "@kyvg/vue3-notification"
export default {
  name: "Login",
  data() {
    return {
      details: {
        email: null,
        password: null
      },
      showError: false,
      settings: null,
      base_url: this.$store.state.baseUrl,
    };
  },
 
  mounted() {
    this.$store.commit("setErrors", {})
 
    this.$store.commit('loading', true)
    axios.get(this.base_url+'api/app/settings')
    .then((response) => {
      this.$store.commit('loading', false)
        this.settings = response.data
        // console.log(this.settings)
    })
    .catch(error => console.log(error))
  },
 
  methods: {
    login()
    {
     
      this.$store.commit('loading', true)
      if(this.terms == false)
      {
 
      }
      axios.post(this.base_url+'api/login',this.details)
      .then((response) => {
        localStorage.setItem("authToken", response.data.token);
        localStorage.setItem("user",JSON.stringify(response.data.user));
        notify({
          type: "success",
          title: "Register",
          text: response.data.message,
        });
        window.location.href = "/"
      })
      .catch((error) => {
        // console.log('not working')
        notify({
          type: "error",
          title: "Login",
          text:  "Email or Password incorrect",
        })
      })
      .finally(() =>this.$store.commit('loading', false))
    },
   
  }
};
</script>
 
 
 
<style scoped>
/* Base Styles */
.login-container {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
 
.main-wrapper {
  display: flex;
  flex-direction: column; /* Default to column layout on small screens */
  width: 100%;
  max-width: 1200px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff; /* Ensure background color is white for better visibility */
}
 
.image-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
 
.login-image {
  max-width: 100%;
  height: auto;
}
 
.content-container {
  flex: 1;
  padding: 20px;
}
 
/* Title Style */
.title {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}
 
/* Input Group Styles */
.input-group {
  margin-bottom: 20px;
}
 
.input-label {
  display: block;
  font-weight: 700;
  color: #333;
}
 
.input-field {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
 
/* Error Message Style */
.error-message {
  color: red;
  text-align: center;
  margin-top: 10px;
}
 
/* Button Container */
.button-container {
  margin-top: 20px;
}
 
.login-button {
  width: 100%;
  padding: 10px;
  background-color: rgb(153,0,0);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
 
/* Links Container */
.links-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
 
.forgot-password-link {
  color: #000;
  font-weight: 500;
  text-decoration: none;
}
 
.signup-container {
  display: flex;
  align-items: center;
}
 
.no-account-text {
  margin-right: 5px;
}
 
.signup-link {
  color: #000;
  font-weight: 500;
  text-decoration: none;
}
 
/* Media Queries for Large Screens */
@media (min-width: 1024px) {
  .main-wrapper {
    flex-direction: row; /* Row layout for large screens */
  }
 
  .image-container {
    padding: 40px;
  }
 
  .content-container {
    padding: 40px;
  }
}
 
/* Media Queries for Small Screens */
@media (max-width: 767px) {
  .main-wrapper {
    flex-direction: column; /* Column layout for small screens */
  }
 
  .image-container {
    display: none; /* Hide the image container on small screens */
  }
 
  .title {
    font-size: 1.5rem; /* Adjust title size for smaller screens */
  }
 
  .input-field {
    padding: 8px;
  }
 
  .login-button {
    padding: 12px;
  }
 
  .links-container {
    flex-direction: column;
    align-items: center;
  }
 
  .signup-container {
    margin-top: 10px;
  }
}
</style>
 